<template>
  <div>
    <div class="d-flex align-items-center justify-content-end">
      <b-button
        variant="gradient-primary"
        @click="submit"
      >
        <i
          class="fad fa-cloud-download-alt"
          style="color: #fff"
        />
        บันทึกข้อมูล
      </b-button>
    </div>
    <b-row class="match-height mt-1">
      <b-col md="12">
        <b-card>
          <h3>
            <i class="fad fa-university" />
            จัดการ Agent
          </h3>
          <b-row>
            <b-col md="3">
              <b-form-group
                label="Prefix"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label1"
                  v-model="agent.prefix"
                  placeholder="prefix"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="site"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label1"
                  v-model="agent.site"
                  placeholder="site"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="เครดิตรวม"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label1"
                  v-model="agcredit.total"
                  placeholder="เครดิตรวม"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="เครดิตคงเหลือ"
                label-for="customDelimiter"
              >
                <h3>{{ agcredit.balance }}</h3>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h3>
            <i class="fad fa-university" /> จัดการเลขบัญชีธนาคารลูกค้าโอนเข้า
          </h3>
          <b-form-group>
            <v-select
              v-model="agent.dip_bank_code"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="banklist"
              :reduce="name => name.bankcode"
            />
          </b-form-group>
          <b-form-group
            label="เลขที่บัญชี"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label1"
              v-model="agent.dip_bank_accno"
              placeholder="เลขที่บัญชี"
            />
          </b-form-group>

          <b-form-group
            label="ชื่อบัญชี"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.dip_bank_accname"
              placeholder="ชื่อบัญชี"
            />
          </b-form-group>
          <b-form-group
            label="internet banking username"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.dip_bank_username"
              placeholder="username"
            />
          </b-form-group>
          <b-form-group
            label="internet banking password"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.dip_bank_password"
              placeholder="password"
            />
          </b-form-group>
          <b-form-group
            label="เบอร์โทร ที่ใช้รับ SMS"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.dip_bank_sms"
              placeholder="เบอร์โทร"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h3>
            <i class="fad fa-university" />
            จัดการเลขบัญชีธนาคารโอนออกให้ลูกค้า
          </h3>

          <b-tabs pills>
            <b-tab
              title="บัญชีธนาคารที่ 1"
              active
            >
              <div>
                <b-form-group>
                  <v-select
                    v-model="agent.wit_bank1_code"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="banklist"
                    :reduce="name => name.bankcode"
                  />
                </b-form-group>
                <b-form-group
                  label="เลขที่บัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label1"
                    v-model="agent.wit_bank1_accno"
                    placeholder="เลขที่บัญชี"
                  />
                </b-form-group>

                <b-form-group
                  label="ชื่อบัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="agent.wit_bank1_accname"
                    placeholder="ชื่อบัญชี"
                  />
                </b-form-group>
                <b-form-group
                  label="internet banking username"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="agent.wit_bank1_username"
                    placeholder="username"
                  />
                </b-form-group>
                <b-form-group
                  label="internet banking password"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="agent.wit_bank1_password"
                    placeholder="password"
                  />
                </b-form-group>
                <b-form-group
                  label="เบอร์โทร ที่ใช้รับ SMS"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="agent.wit_bank1_sms"
                    placeholder="เบอร์โทร"
                  />
                </b-form-group>
              </div>
            </b-tab>
            <b-tab title="บัญชีธนาคารที่ 2">
              <div>
                <b-form-group>
                  <v-select
                    v-model="agent.wit_bank2_code"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="banklist"
                    :reduce="name => name.bankcode"
                  />
                </b-form-group>
                <b-form-group
                  label="เลขที่บัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label1"
                    v-model="agent.wit_bank2_accno"
                    placeholder="เลขที่บัญชี"
                  />
                </b-form-group>

                <b-form-group
                  label="ชื่อบัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="agent.wit_bank2_accname"
                    placeholder="ชื่อบัญชี"
                  />
                </b-form-group>
                <b-form-group
                  label="internet banking username"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="agent.wit_bank2_username"
                    placeholder="username"
                  />
                </b-form-group>
                <b-form-group
                  label="internet banking password"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="agent.wit_bank2_password"
                    placeholder="password"
                  />
                </b-form-group>
                <b-form-group
                  label="เบอร์โทร ที่ใช้รับ SMS"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="agent.wit_bank2_sms"
                    placeholder="เบอร์โทร"
                  />
                </b-form-group>
              </div>
            </b-tab>
            <b-tab title="บัญชีธนาคารที่ 3">
              <div>
                <b-form-group>
                  <v-select
                    v-model="agent.wit_bank3_code"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="banklist"
                    :reduce="name => name.bankcode"
                  />
                </b-form-group>
                <b-form-group
                  label="เลขที่บัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label1"
                    v-model="agent.wit_bank3_accno"
                    placeholder="เลขที่บัญชี"
                  />
                </b-form-group>

                <b-form-group
                  label="ชื่อบัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="agent.wit_bank3_accname"
                    placeholder="ชื่อบัญชี"
                  />
                </b-form-group>
                <b-form-group
                  label="internet banking username"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="agent.wit_bank3_username"
                    placeholder="username"
                  />
                </b-form-group>
                <b-form-group
                  label="internet banking password"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="agent.wit_bank3_password"
                    placeholder="password"
                  />
                </b-form-group>
                <b-form-group
                  label="เบอร์โทร ที่ใช้รับ SMS"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="agent.wit_bank3_sms"
                    placeholder="เบอร์โทร"
                  />
                </b-form-group>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card>
          <h3><i class="fad fa-gifts" /> จำนวนเทินที่ลูกค้าไม่รับโปร</h3>
          <b-form-group
            label="จำนวนเทิน"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.turn_nopro"
              type="number"
              placeholder="จำนวนเทิน"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h3>
            <i class="fad fa-donate" /> ยอดถอนเครดิตสูงสุด
            <small>(โดยไม่ต้อง approve)</small>
          </h3>
          <b-form-group
            label="ถอนเครดิตสูงสุด"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.withdraw_approve"
              type="number"
              placeholder="ถอนเครดิตสูงสุด"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h3><i class="fab fa-ethereum" /> ยอดถอนต่อครั้ง</h3>
          <b-form-group
            label="ถอนเครดิตต่ำสุด"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.withdraw_min"
              type="number"
              placeholder="ถอนเครดิตต่ำสุด"
            />
          </b-form-group>
          <b-form-group
            label="ถอนเครดิตสูงสุด"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.withdraw_max"
              type="number"
              placeholder="ถอนเครดิตสูงสุด"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h3><i class="fad fa-wallet" /> จัดการ truemoney ขาเข้า</h3>
          <b-form-group
            label="เบอร์ทรูมันนี่"
            label-for="customDelimiter"
          >
            <cleave
              id="customDelimiter"
              v-model="agent.truemoney_acc"
              class="form-control"
              :raw="false"
              :options="options.customDelimiter"
              placeholder=""
            />
          </b-form-group>
          <b-form-group
            label="ชื่อบัญชี"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.truemoney_name"
              placeholder="ชื่อบัญชี"
            />
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    Cleave,
    BFormGroup,
    vSelect,
    BFormInput,
    BButton,
    BTab,
    BTabs,
  },
  data() {
    return {
      banklist: [],
      agent: '',
      agcredit: { total: 0, balance: 0 },
      selected: { title: '' },
      option: [{ title: 'Square' }],
      options: {
        customDelimiter: {
          delimiters: ['-', '-', '-'],
          blocks: [3, 3, 4],
          uppercase: true,
        },
      },
    }
  },
  mounted() {
    this.Get_bank()
    this.getAgent()
    this.getAGcredit()
  },
  methods: {
    Get_bank() {
      this.$http
        .get('https://api.sabaideelotto.com/api/bank/list')
        .then(response => {
          this.banklist = response.data
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    getAgent() {
      this.$http
        .get(
          `https://api.sabaideelotto.com/api/agent/adminshow/${this.$route.params.id}`,
        )
        .then(response => {
          this.agent = response.data
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    getAGcredit() {
      this.$http
        .get(
          `https://api.sabaideelotto.com/api/agent/adminagcredit/${this.$route.params.id}`,
        )
        .then(response => {
          if (response.data) {
            this.agcredit = response.data
          }

          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    submit() {
      // const formData = {
      //   name: this.bankname,
      //   bankcode: this.bankcode,
      // }
      if (this.agent.id) {
        this.$http
          .post(
            `https://api.sabaideelotto.com/api/agent/adminupdate/${this.$route.params.id}`,
            this.agent,
          )
          .then(() => {
            this.getAgent()
            this.Success('แก้ไขข้อมูล สำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      } else {
        this.$http
          .post('https://api.sabaideelotto.com/api/agent/store', this.agent)
          .then(() => {
            this.getAgent()
            this.Success('เพิ่มข้อมูล สำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      }
      const formData = {
        total: this.agcredit.total,
      }
      this.$http
        .post(
          `https://api.sabaideelotto.com/api/agent/adminstoreagcredit/${this.$route.params.id}`,
          formData,
        )
        .then(() => {
          this.show = false
          this.getAGcredit()
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
i {
  color: #2e5d92;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
